<template>
  <section class="policy-list">
    <div class="show-table-maxscreen">
      <div class="form-item">
        <span>Effective Date</span>
        <div class="margin-top">
          <v-date-picker
            v-model="date"
            type="daterange"
            :isPicker="true"
            @change="handlerChange"
          />
        </div>

      </div>
      <div class="flex title-tip">
        <div class="flex">
          <span>Total Policies </span>
          <span class="number">{{summary.policy_num}}</span>
        </div>
        <div class="flex">
          <span>Total Premium   </span>
          <span class="number">{{summary.total_premium|formatMoney(2,true)}}</span>
        </div>
        <div class="flex">
          <span>Total Commission for RM   </span>
          <span class="number">{{summary.owner_commission_vl|formatMoney(2,true)}}</span>
        </div>
        <!--        <div class="flex">-->
        <!--          <span>Total Referrer fee  </span>-->
        <!--          <span class="number">{{summary.referrer_commission_vl|formatMoney(2,true)}}</span>-->
        <!--        </div>-->
      </div>

      <el-table
        :data="tableData"
        style="width: 100%"
        :header-row-class-name="'table-header'"
        @row-click="rowClick">
        <el-table-column
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Name</span>
              <v-input
                v-model="company_name"
                placeholder="Search"
                @keyEnter="handlerChange"></v-input>
            </div>
          </template>
          <template slot-scope="scope">
            <div class="flex algin-flex">
              <!--              <span-->
              <!--                v-if="showOtherInsurer(scope.row.company_id)"-->
              <!--                class="company-logo">-->

              <!--                <img-->
              <!--                  :src="getOptionData('insureSVGOptions',scope.row.company_id).logo"-->
              <!--                  alt="company" />-->

              <!--              </span>-->
              <span>{{scope.row.company_name}}</span>

            </div>
          </template>
        </el-table-column>
        <el-table-column

        >
          <template slot="header">
            <div class="table-slot-header">
              <span>No. of Policies</span>
              <v-input
                v-model="policy_nums"
                placeholder="Search"
                type="number"
                @keyEnter="handlerChange"></v-input>
            </div>
          </template>
          <template slot-scope="scope">
            <span>{{toFixed(scope.row.policy_nums)}}</span>
          </template>
        </el-table-column>

        <el-table-column
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Total Gross Premium</span>
              <v-min-max ></v-min-max>
            </div>
          </template>
          <template slot-scope="scope">
            <span> {{scope.row.total_gross_premium|formatMoney(2,true)}}</span>
          </template>
        </el-table-column>
        <el-table-column
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Total Commission</span>
              <v-min-max></v-min-max>
            </div>
          </template>
          <template slot-scope="scope">
            <span> {{scope.row.total_owner_commission_vl|formatMoney(2,true)}}</span>
          </template>

        </el-table-column>
        <el-table-column
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Total Net Premium</span>
              <v-min-max></v-min-max>
            </div>
          </template>
          <template slot-scope="scope">
            <span> {{scope.row.total_no_pay_insurer_net_vl|formatMoney(2,true)}}</span>
          </template>

        </el-table-column>
        <el-table-column
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Coming Bill</span>
              <v-min-max></v-min-max>
            </div>
          </template>
          <template slot-scope="scope">
            <span> {{scope.row.total_no_pay_insurer_net_vl|formatMoney(2,true)}}</span>
          </template>

        </el-table-column>
      </el-table>
    </div>
    <div class="show-table-minscreen">
      <div class="search-box ">
        <div
          class="search-title theme-second-blue"
          @click="show = !show">
          <div class="flex flex-center">
            <font-awesome-icon icon="filter"></font-awesome-icon>
            <span class="is-bold">Filter & Sort</span>
          </div>
          <font-awesome-icon :icon="show?'sort-up':'sort-down'"></font-awesome-icon>
        </div>
        <el-collapse-transition>
          <div
            v-show="show"
          >
            <div  class="margintop">
              <el-row>
                <v-row-col>
                  <span class="is-bold">ID</span>
                  <v-input placeholder="Search"></v-input>
                </v-row-col>

                <v-row-col>
                  <span class="is-bold">Name</span>
                  <v-input placeholder="Search"></v-input>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold">Policy No.</span>
                  <v-input placeholder="Search"></v-input>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold">No. of Polices</span>
                  <v-select></v-select>
                </v-row-col>
              </el-row>
            </div>
          </div>
        </el-collapse-transition>
      </div>

      <div
        v-for="(item,index) in tableData"
        :key="index"
        class="table"
        @click="rowClick(item)">


        <div
          class="tr">
          <span>Name</span>
          <span>{{item.company_name}}</span>
        </div>
        <div
          class="tr">
          <span>No. of Policies</span>
          <span>{{toFixed(item.policy_nums)}}</span>
        </div>

        <div
          class="tr">
          <span>Total Gross Premium</span>
          <span>{{item.total_owner_commission_vl|formatMoney(2,true)}}</span>
        </div>
        <div
          class="tr">
          <span>Total Commision</span>
          <span>{{item.total_owner_commission_vl|formatMoney(2,true)}}</span>
        </div>
        <div
          class="tr">
          <span>Total Net Premium</span>
          <span>{{item.total_no_pay_insurer_net_vl|formatMoney(2,true)}}</span>
        </div>
        <div
          class="tr">
          <span>Coming Bill</span>
          <span>{{item.total_no_pay_insurer_net_vl|formatMoney(2,true)}}</span>
        </div>
      </div>
    </div>
    <v-pagination
      :total="pageConfig.total"
      :current="pageConfig.currentPage"
      @handle-current-change="currentChange"
      @handle-size-change="handleSizeChange" />
  </section>
</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOptions from '@/mixins/options'
import {getInsurerList,getInsurerSummary} from '@api/insurer'
import {mapActions} from 'vuex'
export default {
  name: 'insurer',
  mixins: [MixinOptions,MixinFormat],
  props:{
    type:{
      type:Number,
      default:1,//1 all ,2 draft  3 pending d 4 renew  5 pai 6 expired
    }
  },
  data(){

    return {
      show:false,
      date:'',
      pageConfig: {
        //  分頁配置
        size:20,
        currentPage: 1,
        total: 10
      },
      tableData: [],
      summary:{},
      policy_nums:'',
      company_name:'',
    }
  },
  computed:{
  },
  created(){
    this.network().getInsurerSummary()
    this.network().getInsurerList()
  },
  methods:{
    ...mapActions('statement',['setStatementTab']),

    showOtherInsurer(company_id){
      let arr =  [1,4,11,3]
      return arr.includes(company_id)
    },
    handlerChange(){
      this.network().getInsurerList()
    },
    rowClick({company_id}){
      this.setStatementTab('1')
      this.$router.push('/member/insurer/statement/'+company_id)
    },
    currentChange(page) {
      this.pageConfig.currentPage = page
      this.network().getInsurerList()
    },
    handleSizeChange(size){
      this.pageConfig.size =size
      this.network().getInsurerList()
    },
    network() {
      return {
        getInsurerList: async () => {
          const { data } = await getInsurerList({
            effective_date_start: this.date? this.date[0]: '',
            effective_date_end: this.date? this.date[1]: '',
            company_name: this.company_name,
            policy_nums: this.policy_nums,
            size:this.pageConfig.size,
            page: this.pageConfig.currentPage,
          })
          this.tableData = data.data
          let {meta} = data
          this.pageConfig.total = meta.total
        },
        getInsurerSummary: async () => {
          const { data } = await getInsurerSummary({
          })
          this.summary = data
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/policyList';
  .margin-top{
    margin-top: 10px;
    .v-date-picker{
      margin-left: 0!important;
    }
  }
  .company-logo{
    height: 20px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      display: block;
      width: 60%;
    }
  }
  .algin-flex{
    align-items: center;
  }
.title-tip{
  color: #4A5568;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
  .number{
    font-size: 16px;
    font-weight: normal;
    margin: 0 25px 0 12px;
  }
}
</style>
