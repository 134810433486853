import axios from '@/jslib/axios'

export const getInsurerList = params => {
  return axios({
    method: 'get',
    url: '/admin/insurer/list',
    params
  })
}


export const getInsurerDetail = params => {
  return axios({
    method: 'get',
    url: '/admin/insurer/detail',
    params
  })
}

export const setInsurerSettle = data => {
  return axios({
    method: 'post',
    url: '/admin/insurer/settle',
    data
  })
}
export const getInsurerRecord = params => {
  return axios({
    method: 'get',
    url: '/admin/insurer/record',
    params
  })
}
export const getInsurerRecordExport = params => {
  return axios({
    method: 'get',
    url: '/admin/insurer/record/export',
    responseType: 'blob',
    params
  })
}
export const getInsurerStatement = params => {
  return axios({
    method: 'get',
    url: '/admin/insurer/statement',
    params
  })
}
export const insurerStatementDetail = params => {
  return axios({
    method: 'get',
    url: '/admin/insurer/statement/detail',
    params
  })
}
export const insurerUndo = data => {
  return axios({
    method: 'post',
    url: '/admin/insurer/undo',
    data
  })
}
export const getInsurerReports = params => {
  return axios({
    method: 'get',
    url: '/admin/insurer/reports',
    params
  })
}
export const getInsurerSummary = params => {
  return axios({
    method: 'get',
    url: '/admin/insurer/list/summary',
    params
  })
}
export const insurerReportDown = params => {
  return axios({
    method: 'get',
    url: '/admin/insurer/reports/download',
    responseType: 'blob',
    params
  })
}
export const insurerStatementDown = params => {
  return axios({
    method: 'get',
    url: '/admin/insurer/statement/download',
    responseType: 'blob',
    params
  })
}
export const insurerSummary = params => {
  return axios({
    method: 'get',
    url: '/admin/insurer/statement/detail/summary',
    params
  })
}
